<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, k) in error_messages">
          <span v-bind:key="k">
            <li v-for="(message, i) in error" v-bind:key="i">{{ message }}</li>
          </span>
        </template>
      </ul>
    </div>

    <v-stepper v-model="step" alt-labels>
      <v-stepper-header class="p-3">
        <h1>Почивки</h1>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-12" color="grey lighten-1">
            <v-row>
              <InputTime v-model="workBreak.startTime" label="Начало" />
              <InputTime label="Край" v-model="workBreak.endTime" />
            </v-row>

            <v-btn
              @click="saveWokBreak()"
              class="btn btn-success btn-elevate kt-login__btn-primary text-white"
              >{{ $t("BUTTON.SAVE") }}
            </v-btn>
            <v-progress-circular
              class="just-center"
              indeterminate
              color="primary"
              v-if="isLoadingWorkBreaks"
            ></v-progress-circular>

            <br />
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import {
  GET_WORK_BREAK,
  SAVE_WORK_BREAK,
  UPDATE_WORK_BREAK
} from "@/store/workBreaks.module.js";

import _ from "lodash";

import { mapGetters, mapState } from "vuex";
import InputTime from "@/components/InputTime";

export default {
  name: "WorkBreakCreateUpdate",

  components: { InputTime },
  computed: {
    ...mapGetters(["isLoadingWorkBreaks"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  data() {
    return {
      step: 1,
      error_messages: [],
      addNew: false,
      model: "work_break",
      workBreak: {
        startTime: null,
        endTime: null
      }
    };
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      let current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        { title: "Почивки", route: { name: "workBreakList" } },
        { title: current_page }
      ]);
    }, 300);

    if (typeof this.$route.params.id !== "undefined") {
      this.getData();
    }
  },
  methods: {
    async saveWokBreak() {
      let vm = this;

      vm.error_messages = [];

      let action =
        typeof this.$route.params.id !== "undefined"
          ? UPDATE_WORK_BREAK
          : SAVE_WORK_BREAK;

      vm.$store
        .dispatch(action, { payload: vm.workBreak })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });
          vm.$router.push({ name: "workBreakList" });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },

    getData: function() {
      let vm = this;
      this.error_messages = [];
      let id = this.$route.params.id;
      this.$store
        .dispatch(GET_WORK_BREAK, id)
        .then(data => {
          vm.$nextTick(function() {
            vm.workBreak = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "workBreakList" });
          }
        });
    }
  }
};
</script>
